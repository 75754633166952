import Vue from "vue";
import ToastificationContent from "../../views/components/messages.vue";
import i18n from "@/libs/i18n";

export default {
    UPDATE_WINDOW_WIDTH(state, val) {
        state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
        state.shallShowOverlay =
            val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_LANG(state, lang) {
        if (state.locales.includes(lang.locale)) {
            state.language = lang;
        }
    },
    projectDirection: (state, lang) => {
        if (lang === "ar") {
            state.direction = true;
        }
        if (lang !== "ar") {
            state.direction = false;
        }
    },
    successMessage: (state, data) => {
        Vue.$toast(
            {
                component: ToastificationContent,
                props: {
                    title: i18n.t(`success_messages.${data}`),
                },
            },
            {
                toastClassName: "success-class",
                position: "bottom-right",
            }
        );
        state.messages = "success";
    },
    failMessage: (state, data) => {
        state.messages = "error";
        Vue.$toast(
            {
                component: ToastificationContent,
                props: {
                    title: i18n.t(`error_messages.${data}`),
                },
            },
            {
                toastClassName: "fail-class",

                position: "bottom-right",
            }
        );
    },
    loadingFinish: (state) => {
        state.loading = false;
    },
    loadingStart: (state) => {
        state.loading = true;
    },
};
