export default {
    setClients: (state, data) => {
        state.allClients = data.content;
        state.totalClients = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
    setClientDetails: (state, data) => {
        state.clientDetails = data;
    },
};
