import Vue from "vue";

export default async function middleware({ to, next }) {
    const loginRoutes = [
        "auth-login",
        "auth-forgot-password",
        "auth-reset-password",
    ];

    const loginData = Vue.$cookies.get("super-leaq-login-data", {
        parseJSON: true,
    });

    if (loginData && loginRoutes.includes(to.name)) {
        return next({
            name: "dashboard",
        });
    }

    if (!loginData && !loginRoutes.includes(to.name)) {
        // Redirect to login if not logged in
        return next({ name: "auth-login" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && loginData) {
        const userData = localStorage.getItem("user");
        // next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }

    return next();
}
