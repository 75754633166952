// import axios from '@/plugins/axios';
import router from "@/router";

export default {
  async loadDashboard({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get("super/dashboard")
      .then((respo) => {
        if (respo.status === 200) {
          commit("setDashboard", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadFinancial({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(
        `super/financial?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&status=${data.status}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setFinancial", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadDetailsFinancial({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(
        `super/financial/${data.center_id}?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setDetailsFinancial", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async addFinancial({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("super/financial", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "saved_successfully");
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async uploadFiles({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("file/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
