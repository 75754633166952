import auth from '../middlewareAuth'

export default [
  {
    path: '/',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/system/Dashboard.vue'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'bookings',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: ':id',
            name: 'details-booking',
            component: () => import('@/views/system/BookingDetails.vue'),
            meta: {
              pageTitle: 'تفاصيل الحجز',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'centers',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'centers',
            component: () => import('@/views/system/centers/Centers.vue'),
            meta: {
              pageTitle: 'المراكز',
              middleware: [auth],
            },
          },
          {
            path: 'add',
            name: 'add-center',
            component: () => import('@/views/system/centers/Add.vue'),
            meta: {
              pageTitle: 'اضافة مركز',
              middleware: [auth],
            },
          },
          {
            path: ':id',
            name: 'details-center',
            component: () => import('@/views/system/centers/Details.vue'),
            meta: {
              pageTitle: 'تفاصيل المراكز',
              middleware: [auth],
            },
          },
          {
            path: 'edit/:id',
            name: 'edit-center',
            component: () => import('@/views/system/centers/Edit.vue'),
            meta: {
              pageTitle: 'تعديل مركز',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'clients',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'clients',
            component: () => import('@/views/system/clients/Clients.vue'),
            meta: {
              pageTitle: 'العملاء',
              middleware: [auth],
            },
          },
          {
            path: ':id',
            name: 'details-client',
            component: () => import('@/views/system/clients/Details.vue'),
            meta: {
              pageTitle: 'تفاصيل العميل',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'financial',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'financial',
            component: () => import('@/views/system/financial/Financial.vue'),
            meta: {
              pageTitle: 'العمليات المالية',
              middleware: [auth],
            },
          },
          {
            path: ':id',
            name: 'details-financial',
            component: () => import('@/views/system/financial/Details.vue'),
            meta: {
              pageTitle: 'تفاصيل حساب المركز',
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: 'settings',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'settings',
            component: () => import('@/views/system/settings/Settings.vue'),
            meta: {
              pageTitle: 'الإعدادات العامة',
              middleware: [auth],
            },
          },
          {
            path: 'analyzes',
            component: {
              render(c) {
                return c('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'settings-analyzes',
                component: () => import('@/views/system/settings/Analyzes.vue'),
                meta: {
                  pageTitle: 'التحاليل التي توفرها المنصة',
                  middleware: [auth],
                },
              },
              {
                path: 'edit/:id',
                name: 'settings-edit-analyzes',
                component: () => import('@/views/system/settings/EdirAnalyzes.vue'),
                meta: {
                  pageTitle: 'تعديل تحليل',
                  middleware: [auth],
                },
              },
            ],
          },
          {
            path: 'profile',
            name: 'settings-profile',
            component: () => import('@/views/system/settings/Profile.vue'),
            meta: {
              pageTitle: 'تهيئة بيانات المركز',
              middleware: [auth],
            },
          },
        ],
      },
    ],
  },
]
