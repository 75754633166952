export default {
    getAllBookings(state) {
        return state.allBookings;
    },
    getTotalBookings(state) {
        return state.totalBookings;
    },
    getBookingDetails(state) {
        return state.bookingDetails;
    },
};
