export default {
  allCenters: null,
  centerDetails: null,
  totalCenters: {
    totalItems: null,
    totalPages: null,
  },
  analyses: null,
  analysesDetails: null,
};
