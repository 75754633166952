export default {
  async loadBookings({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(
        `super/bookings?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&status=${data.status}&search=${data.search}&center_id=${data.center_id}&client_id=${data.client_id}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setBookings", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadBookingDetails({ commit }, id) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`super/bookings/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setBookingDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
