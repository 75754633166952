export default {
  getDashboard(state) {
    return state.dashboardData;
  },
  getFinancial(state) {
    return state.financial;
  },
  getTotalFinancial(state) {
    return state.totalFinancial;
  },
  getDetailsFinancial(state) {
    return state.detailsFinancial;
  },
  getTotalDetailsFinancial(state) {
    return state.totalDetailsFinancial;
  },
};
