export default {
  getAllCenters(state) {
    return state.allCenters;
  },
  getTotalCenters(state) {
    return state.totalCenters;
  },
  getCenterDetails(state) {
    return state.centerDetails;
  },
  getAnalyses(state) {
    return state.analyses;
  },
  getAnalysesDetails(state) {
    return state.analysesDetails;
  },
};
