import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import profile from "./profile";
import centers from "./centers";
import bookings from "./bookings";
import clients from "./clients";
import utilities from "./utilities";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        profile,
        centers,
        bookings,
        clients,
        utilities,
    },
    strict: process.env.DEV,
});
