import router from "@/router";

export default {
  async loadProfileInfo({ commit }, data) {
    commit("loadingStart", null);
    const loginData = this._vm.$cookies.get("super-leaq-login-data", {
      parseJSON: true,
    });
    return await this._vm.$http
      .get("super/user/me")
      .then((respo) => {
        if (respo.status === 200) {
          commit("setProfileInfo", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async updateProfile({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("super/user/profile/update", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async changePassword({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("auth/password/change", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
