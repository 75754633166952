export default {
    setLoggedInuser: (state, data) => {
        state.isLoggedIn = data;
        // set('drawer')
    },
    setEmailTokenId(state, data) {
        state.emailTokenId = data;
    },
    setOtpVerification(state, data) {
        state.OtpVerification = data;
    },
};
