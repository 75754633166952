export default {
    getIsLoggedIn(state) {
        return state.isLoggedIn;
    },
    getEmailTokenId(state) {
        return state.emailTokenId;
    },
    getOtpVerification(state) {
        return state.OtpVerification;
    },
};
