export default {
  getAllClients(state) {
    return state.allClients;
  },
  getTotalClients(state) {
    return state.totalClients;
  },
  getClientDetails(state) {
    return state.clientDetails;
  },
};
