export default {
    setDashboard: (state, data) => {
        state.dashboardData = data.content;
    },
    setFinancial: (state, data) => {
        state.financial = data.content;
        state.totalFinancial = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
    setDetailsFinancial: (state, data) => {
        state.detailsFinancial = data.content;
        state.totalDetailsFinancial = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
};
