export default {
  async loadClients({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(
        `super/clients?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&status=${data.status}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setClients", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadClientDetails({ commit }, id) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`super/clients/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setClientDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async changeClientStatus({ commit }, id) {
    commit("loadingStart", null);
    return await this._vm.$http
      .patch(`super/clients/status/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "change_status_successfully");
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
