export default {
    setBookings: (state, data) => {
        state.allBookings = data.content;
        state.totalBookings = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
    setBookingDetails: (state, data) => {
        state.bookingDetails = data;
    },
};
