export default {
  async loadCenters({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(
        `super/centers?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&status=${data.status}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenters", respo.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadCenterDetails({ commit }, id) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`super/centers/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenterDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async addCenter({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("super/centers", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenterDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async editCenter({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .put(`super/centers/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setCenterDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async changeCenterStatus({ commit }, id) {
    commit("loadingStart", null);
    return await this._vm.$http
      .patch(`super/centers/status/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "change_status_successfully");
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadAnalyses({ commit }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get("super/analyses")
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAnalyses", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadAnalysesDetails({ commit }, id) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(`super/analyses/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAnalysesDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async editAnalyses({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .patch(`super/analyses/update/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAnalysesDetails", respo.data.data.content);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async changeAnalysesStatus({ commit }, id) {
    commit("loadingStart", null);
    return await this._vm.$http
      .patch(`super/analyses/status/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("successMessage", "change_status_successfully");
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
