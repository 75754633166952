export default {
  dashboardData: null,
  financial: null,
  totalFinancial: {
    totalItems: null,
    totalPages: null,
  },
  detailsFinancial: null,
  totalDetailsFinancial: {
    totalItems: null,
    totalPages: null,
  },
};
