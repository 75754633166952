import Vue from 'vue'
import logo from './logo.svg'
import bellIcon from './bellIcon.svg'
import bellIconLight from './bellIcon-light.svg'
import dashboardNav from './dashboard-nav.svg'
import financialNav from './financial-nav.svg'
import centersNav from './centers.svg'
import clientsNav from './clients-nav.svg'
import settingsNav from './settings-nav.svg'
import moon from './moon.svg'
import sun from './sun.svg'
import bgNav from './bg-nav.svg'
import addBooking from './add-booking.svg'
import filter from './filter.svg'
import analyzes from './analyzes.svg'
import users from './users.svg'
import center from './center.svg'
import user from './user.svg'

import generalReports from './general-reports.svg'
import financialReports from './financial-reports.svg'
import workAchievementReports from './work-achievement-reports.svg'
import totalCapture from './total-capture.svg'
import totalIndebtedness from './total-indebtedness.svg'
import eye from './eye.svg'
import Dots3 from './3-dots.svg'
import edit from './edit.svg'
import stop from './stop.svg'
import description from './description.svg'
import stop_2 from './stop-2.svg'
import active from './active.svg'
import loginVector from './login-vector.svg'
import dashboardCenters from './dashboard-centers.svg'
import dashboardClients from './dashboard-clients.svg'
import dashboardBookings from './dashboard-bookings.svg'
import dashboardBookings2 from './dashboard-bookings-2.svg'
import dashboardFinancial from './dashboard-financial.svg'
import dashboardIndebtedness from './dashboard-indebtedness.svg'
import error from "./error.svg";
import errorDark from "./error-dark.svg";


Vue.component('logo-icon', logo)
Vue.component('bellIcon-icon', bellIcon)
Vue.component('bellIcon-light-icon', bellIconLight)
Vue.component('dashboard-nav-icon', dashboardNav)
Vue.component('financial-nav-icon', financialNav)
Vue.component('centers-nav-icon', centersNav)
Vue.component('clients-nav-icon', clientsNav)
Vue.component('settings-nav-icon', settingsNav)
Vue.component('moon-icon', moon)
Vue.component('sun-icon', sun)
Vue.component('bg-nav-icon', bgNav)
Vue.component('add-booking-icon', addBooking)
Vue.component('filter-icon', filter)
Vue.component('analyzes-icon', analyzes)
Vue.component('users-icon', users)
Vue.component('center-icon', center)
Vue.component('user-icon', user)
Vue.component('general-reports-icon', generalReports)
Vue.component('financial-reports-icon', financialReports)
Vue.component('work-achievement-reports-icon', workAchievementReports)
Vue.component('total-capture-icon', totalCapture)
Vue.component('total-indebtedness-icon', totalIndebtedness)
Vue.component('eye-icon', eye)
Vue.component('dots-3-icon', Dots3)
Vue.component('edit-icon', edit)
Vue.component('stop-icon', stop)
Vue.component('description-icon', description)
Vue.component('stop-2-icon', stop_2)
Vue.component('active-icon', active)
Vue.component('login-vector-icon', loginVector)
Vue.component('dashboard-centers-icon', dashboardCenters)
Vue.component('dashboard-clients-icon', dashboardClients)
Vue.component('dashboard-bookings-icon', dashboardBookings)
Vue.component('dashboard-bookings-2-icon', dashboardBookings2)
Vue.component('dashboard-financial-icon', dashboardFinancial)
Vue.component('dashboard-indebtedness-icon', dashboardIndebtedness)
Vue.component("error-icon", error);
Vue.component("error-dark-icon", errorDark);