export default {
    setCenters: (state, data) => {
        state.allCenters = data.content;
        state.totalCenters = {
            totalItems: data.total,
            totalPages: data.last_page,
        };
    },
    setCenterDetails: (state, data) => {
        state.centerDetails = data;
    },
    setAnalyses: (state, data) => {
        state.analyses = data.content;
    },
    setAnalysesDetails: (state, data) => {
        state.analysesDetails = data;
    },
};
